import React from "react";
import "./App.css";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./components/login/login";
import Logout from "./components/login/logout";
import HelpDesk from "./components/contacts/Help";
import ChangePassword from "./components/changePassword/changePassword";
import Cases from "./components/cases/cases";
import CaseCalendar from "./components/calendar/CaseCalendar";
import Header from "./components/header/header";
import Timesheet from "./components/cases/timesheet";
import Statements from "./components/invoices/Statements";
import PendingTasks from "./components/pendingTasks/pendingTasks";
import ForgotPassword from "./components/login/forgotPassword";
import CaseDetails from "./components/cases/caseDetails";
import Demographics from "./components/demographics/demoGraphics";
import PMA from "./components/cases/pma";
import { Button } from "@material-ui/core";
import IdleTimer from "react-idle-timer";
import axios from "axios";
import { REFRESH_TOKEN,IDLE_TIMEOUT } from "./api/baseURL";
import { cacheClear } from "./cacheClear";
import { decryptItem } from "../src/shared/Encrypt";
export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page.
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <>
            <Header history={props.history} />
            <div className="">
              {" "}
              <Component {...props} />
            </div>
          </>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <Redirect to="/cases" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

class App extends React.Component {
  constructor() {
    super();
    this.idleTimer = null
    this.onIdle = this._onIdle.bind(this)

    this.state = {
      oneMinTimeOut: false,
      clickToReset: false,
      seconds: 10,
    };
  }
  handleTabClose = (ev) => {
    ev.preventDefault();  
  };
  componentDidMount() {
    window.addEventListener("unload", this.handleTabClose);
    cacheClear();
  }

  
  componentWillUnmount() {
    window.removeEventListener("unload", this.handleTabClose);
  }

  
  logout = (counter) => {
    localStorage.clear();
    window.location.reload();
    clearInterval(counter);
  };

 
  _onIdle(e) {
    console.log('user is idle', e);
    this.setState({ seconds: 60 });
    const token=localStorage.getItem("token");
    if (token !== null && token !== "" && token !== undefined) {
    this.setState({oneMinTimeOut:true});
  
    const counter = setInterval(() => {
      this.setState({ seconds: this.state.seconds - 1 });
    }, 1000); //Count seconds before user is kicked for inactivity.
    const userNotBack = window.setTimeout(() => {
      console.log("userNotBack keepActive");
      this.state.clickToReset
        ? this.keepActive(userNotBack, counter)
        : this.logout(counter);
      clearTimeout(userNotBack);
    }, 60000); //One minute prompt to stay signed in
  }
  console.log('last active', this.idleTimer.getLastActiveTime())

  }


  keepActive = (timer, counter) => {
    console.log("Kept user logged in");
    if (
      localStorage.getItem("token") !== "" &&
      localStorage.getItem("token") !== null &&
      localStorage.getItem("token") !== undefined
    ) {
      axios
        .post(REFRESH_TOKEN, {
          token: localStorage.getItem("refreshToken"),
          userEmail: decryptItem("neutralsEmail"),
        })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("token", res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
          }
        });
      clearTimeout(timer);
      clearInterval(counter);
    }
  };
  resetTimeOut = () => {
    console.log("resetTimeOut",this.state.clickToReset);
    this.setState({ oneMinTimeOut: false });
    this.setState({ clickToReset: true });
    axios
        .post(REFRESH_TOKEN, {
          token: localStorage.getItem("refreshToken"),
          userEmail: decryptItem("neutralsEmail"),
        })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("token", res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
          }
        });
      
  };

  render() {
    return (
      <div className="App">
        <Switch>
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            
            onIdle={this.onIdle}
            debounce={250}
            timeout={IDLE_TIMEOUT}
            // onIdle={this.onIdle}
            // timeout={IDLE_TIMEOUT} //After  60 minutes the user will be prompted to stay logged in for another minute.
          >

            {this.state.oneMinTimeOut ? (
              <div className="oneMinModal">
                <div className="innerModal">
                  <p>
                    You will be logged out due to inactivity in{" "}
                    {this.state.seconds} seconds
                  </p>
                  <Button onClick={this.resetTimeOut} variant="contained">
                    I'm back!
                  </Button>
                </div>
              </div>
            ) : null}
            <PublicRoute restricted={false} path="/" component={Login} exact />
            <PublicRoute
              restricted={false}
              path="/logout"
              component={Logout}
              exact
            />

            <PublicRoute
              restricted={false}
              path="/forgotPassword"
              component={ForgotPassword}
              exact
            />

            <PublicRoute
              path="/changePassword/:verifyCode"
              component={ChangePassword}
              exact
            />
            <PrivateRoute path="/cases" component={Cases} exact />
            <PrivateRoute path="/CaseCalendar" component={CaseCalendar} exact />
            <PrivateRoute path="/pendingTasks" component={PendingTasks} exact />
            <PrivateRoute path="/timesheet" component={Timesheet} exact />
            <PrivateRoute path="/Statements" component={Statements} exact />
            <PrivateRoute
              //path="/caseDetails/:hearingCode/:caseType/:goBack?/:pendingAt?"
              path="/caseDetails"
              component={CaseDetails}
              exact
            />
            <PrivateRoute path="/demographics" component={Demographics} exact />
            {/* <PrivateRoute path="/submissions" component={Submissions} exact /> */}
            <PrivateRoute path="/pma/:hearingCode" component={PMA} exact />
            <PrivateRoute path="/HelpDesk" component={HelpDesk} exact />
          </IdleTimer>
        </Switch>
      </div>
    );
  }
}

export default App;
