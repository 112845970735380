import React, { useState, useEffect, useRef } from "react";
////import ReportViewer from "f1-report-viewer-react";
import Modal from "react-bootstrap/Modal";
import MatDatagrid from "f1-mat-data-grid-react";
import * as Utility from "../../utils/utility";
import axios from "axios";
import AlertDialog from "../../shared/AlertDialog";
import { TIMESHEET_DETAILS, ADD_TIMESHEET_DETAILS,GET_TIMESHEET_FILEWITHSAS } from "../../api/baseURL";
import CommercialTimesheet from "./commercialTimesheet"
import CaseData from "./caseData";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { decryptItem } from "../../shared/Encrypt";
const CommercialTimesheetGrid = (props) => {
    const defaultInstance = defaultLayoutPlugin();
    const myRef = useRef(null);
    const [orderDirection, setorderDirection] = useState("asc");
    const [orderBy, setorderBy] = useState("id");
    const [orderByColumn, setorderByColumn] = useState(2);
    const [totalRecords, settotalRecords] = useState(0);
    const [pageNumber, setpageNumber] = useState(1);
    const recordPerPage = 5;
    const [displayProducts, setdisplayProducts] = useState([]);
    const [initalCasesData, setinitalCasesData] = useState([]);
    const [isdisplayAlert, setdisplayAlert] = useState(false);
    const [showCommertialTimeSheet, setshowCommertialTimeSheet] = useState(false);
    const [index, setIndex] = useState(0);
    const [key, setKey] = useState(0);
    const [notsubmitted, setnotsubmitted] = useState(false);
    const [notsubmittedTimesheet, setnotsubmittedTimesheet] = useState('');
    const [isPreview, setIsPreview] = useState(false);
    const [PreviewResponse, setPreviewResponse] = useState("");

    const [timesheetdetails, settimesheet] = useState('');
    const [deleteTimesheet, setdeleteTimesheet] = useState([]);
    const [deleteIndex, setdeleteTimesheetIndex] = useState(-1);
    
    const hearingCode = parseInt(
        props.hearingCode ? props.hearingCode : props.match.params?.hearingCode
    );
    
  
    function getMonthFromString(mon, status) {
        const setDate = new Date(Date.parse("1 " + mon));
        const currentDateMonth = new Date().getMonth() + 1;
        const currentDateYear = new Date().getFullYear();
        const flag = ((status !== "Accepted") && ((
            currentDateYear === setDate.getFullYear() &&
            currentDateMonth < setDate.getMonth()) || (currentDateYear > setDate.getFullYear())));
        if (flag) {
            setnotsubmitted(true);
            setnotsubmittedTimesheet(mon);
        }
        return flag;
    }
    const executeScroll = () => myRef.current.scrollIntoView()
    useEffect(() => {
        getTimesheets();
    }, []);
    const columns = Utility.IsHearingOfficer() ? [
        {
            title: "Timesheet",
            field: "timesheetName",
            sorting: false,
            render: (rowData) => (
                <div className="action" style={{ display: "flex", alignItems: "center" }}>

                    {getMonthFromString(rowData.timesheetName, rowData.status) && <i
                        style={{ color: 'darksalmon',paddingRight:'5px', }}
                        className="fa fa-warning"
                    ></i> 
                    }
                    {rowData.timesheetName}
                </div>
            ),
        },
        {
            title: "Status",
            field: "status",
            sorting: false,
            render: (rowData) =>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {rowData.status}
                </div>
        },
        {
            title: "Action",
            field: "action",
            sorting: false,
            render: (rowData) =>
            
                <div className="response-submission" style={{ display: "flex", alignItems: "center" }}>
                    <span className="action first-child" onClick={e => (rowData.status === "Accepted") ? viewRecord(rowData.hearingOfficerName,rowData.timesheetName,rowData.fileName) : (rowData.status === "Sent for Review"?editRecord(rowData) : undefined)}>
                        <i style={{ color: (rowData.status === "Accepted" || rowData.status === "Sent for Review") ? '#000' : 'lightgray' }} className="fa fa-eye"></i>
                    </span>
                    <span className="action" onClick={e => (rowData.status !== "Accepted" || rowData.status !== "Sent for Review" ) ? editRecord(rowData) : undefined}>
                        <i style={{ color: (rowData.status === "Accepted" || rowData.status === "Sent for Review") ? 'lightgray' : '#000'  }} className="fa fa-edit"></i>
                    </span>

                    <span className="action" onClick={e => (rowData.status === "Accepted" || rowData.status === "Sent for Review" || 
                    (rowData.commercialtimesheetDetails==null||rowData.commercialtimesheetDetails===undefined|| rowData.commercialtimesheetDetails.length===0)) ? undefined : deleteRecord(rowData)}>
                        <i style={{ color: (rowData.status === "Accepted" || rowData.status === "Sent for Review"||
                        (rowData.commercialtimesheetDetails==null||rowData.commercialtimesheetDetails===undefined|| rowData.commercialtimesheetDetails.length===0)) ? 'lightgray' : '#000' }} className="fa fa-trash"></i>
                    </span>

                </div>
        }
    ] :
        [
            {
                title: "Timesheet",
                field: "timesheetName",
                sorting: false,
                render: (rowData) => (
                    <div className="action" style={{ display: "flex", alignItems: "center" }}>

                        {getMonthFromString(rowData.timesheetName, rowData.status) && <i
                            style={{ color: 'darksalmon' }}
                            className="fa fa-warning"
                        ></i>
                        }&nbsp;&nbsp;
                        {rowData.timesheetName}
                    </div>
                ),
            },
            {
                title: "Hearing Officer",
                field: "hearingOfficerName",
                sorting: false,
                render: (rowData) =>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {rowData.hearingOfficerName}
                    </div>
            },
            {
                title: "Status",
                field: "status",
                sorting: false,
                render: (rowData) =>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {rowData.status}
                    </div>
            },
            {
                title: "Action",
                field: "action",
                className:'timesheet-response',
                sorting: false,
                render: (rowData) =>
                    <div className="response-submission" style={{ display: "flex", alignItems: "center" }}>
                        <span className={`action ${(rowData.status === "Accepted")?"":"disabled-icon"} first-child`} onClick={e => (rowData.status === "Accepted") ? viewRecord(rowData.hearingOfficerName,rowData.timesheetName,rowData.fileName) : undefined}>
                            <i style={{ color: (rowData.status === "Accepted") ? '#000' : 'lightgray' }} className="fa fa-eye"></i>
                        </span>
                        <span className={`action ${(rowData.status === "Accepted")?"disabled-icon":""} `}  onClick={e => (rowData.status !== "Accepted") ? editRecord(rowData) : undefined}>
                            <i style={{ color: (rowData.status === "Accepted") ? 'lightgray' : '#000' }} className="fa fa-edit"></i>
                        </span>

                        <span className={`action ${(rowData.status === "Accepted" || rowData.status === "Sent for Review")?"disabled-icon":""} `} onClick={e =>((rowData.status === "Accepted" || rowData.status === "Sent for Review")
                        || (rowData.commercialtimesheetDetails==null||rowData.commercialtimesheetDetails===undefined|| rowData.commercialtimesheetDetails.length===0))
                        ? undefined : deleteRecord(rowData)}>
                            <i style={{ color: ((rowData.status === "Accepted" || rowData.status === "Sent for Review") || (rowData.commercialtimesheetDetails==null||rowData.commercialtimesheetDetails==undefined|| rowData.commercialtimesheetDetails.length===0)) ? 'lightgray' : '#000' }} className="fa fa-trash"></i>
                        </span>

                    </div>
            }
        ];

    const deleteRecord = (e) => {
        setdisplayAlert(true);
        setdeleteTimesheet(e);
        setdeleteTimesheetIndex(e.index);
    }
    const updatedisplayAlert = (e, type) => {
        if (type === 'delete') {
            console.log(initalCasesData, deleteTimesheet);
            let casedata = initalCasesData.filter(s => s.caseCode === deleteTimesheet.caseCode)
            var deleteobj = {};
            if (casedata.length > 0) {
                casedata = casedata[0];
                casedata.commercialTimesheet[deleteIndex].commercialtimesheetDetails = [];
                deleteobj = casedata;
                deleteobj.commercialTimesheet[deleteIndex].additionalNotes = "";
                const url = `${ADD_TIMESHEET_DETAILS}`;
                axios
                    .post(url, deleteobj, {
                        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            getTimesheets();
                        }
                    });
            }
        }
        setdisplayAlert(e);
    }
    const cancelPreview = () => {
        setIsPreview(false);
    };

    const viewRecord = (hearingOfficerName,TimesheetName,fileName) => {
       
        const _lastname = `${hearingOfficerName !== null
            ? hearingOfficerName.split(" ")[1]
            : ""
            }`;
            const url=`TimeSheet_${_lastname}_01_${TimesheetName}.pdf`
        viewTimesheetResponse(url);
        
    }
    //New Method
    const viewTimesheetResponse = (fileName) => {
        const url1 = `${GET_TIMESHEET_FILEWITHSAS}?caseReference=${props.caseDetails["caseReference"]}&filename=${fileName}`;
        axios
            .get(url1, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res1) => {
                const byteString = atob(res1.data); // Add your base64-encoded byte string here

                // Convert byte string to Uint8Array
                const byteArray = new Uint8Array(byteString.length);
                for (let i = 0; i < byteString.length; i++) {
                    byteArray[i] = byteString.charCodeAt(i);
                }

                // Convert Uint8Array to Blob
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                // Create a URL for the Blob
                const url = URL.createObjectURL(blob);
                setPreviewResponse(url);
                setIsPreview(true);

            }).catch(function(error)
            {
                setPreviewResponse("")              
                
            });
    };
    // New Method End
    const editRecord = (e) => {
        
        setIndex(e.index);
        setKey(Math.random() * 100);        
        settimesheet(e);        
        setshowCommertialTimeSheet(true);
        executeScroll();
        props.displayTimesheet(true, e);
      
    }
    const saveTimesheet = (index1, e) => {
        const products = displayProducts;
        products[index1] = e;
        setdisplayProducts(products);
        setIndex(e.index1);
        settimesheet(e);
        
    }
    const getObject = () => {
       
        return {
            PageNumber: pageNumber,
            PageSize: recordPerPage,
            Sort: orderBy,
            SortDirection: orderDirection,
            Filters: [{
                "filterName": "CaseCode",
                "filterValue": props.caseDetails["caseCode"],
                "filterType": "numeric",
                "filterEq": null
            }],
            NeutralEmail: decryptItem("neutralsEmail"),
            UserType: decryptItem("roleId"),
            UserCode: Utility.IsHearingOfficer() ? decryptItem ("jCode"): decryptItem("userid")
        };
    };
    
    const getTimesheets = () => {

        const url = `${TIMESHEET_DETAILS}`;
        axios
            .post(url, getObject(), {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((res) => {
                if (res.status === 200) {
                    let response = [];
                    response = res.data;
                    if (response !== null && response.data.length > 0) {
                        // time zone start
                        let commercialTimesheetTemp = [];
                        response.data[0].commercialTimesheet.forEach(element => {
                            let commercialtimesheetDetailsTemp = [];
                            element.commercialtimesheetDetails.forEach(cTdetails => {
                                cTdetails.endTime = cTdetails.endTime?
                                    (cTdetails.endTime.indexOf("GMT") ?
                                        Utility.convertUTCDateToUserTimeZone(cTdetails.endTime) :
                                        Utility.convertUTCDateToUserTimeZone(new Date(cTdetails.endTime).toUTCString())
                                    )
                                    : cTdetails.endTime

                                cTdetails.startTime = cTdetails.startTime ?
                                    (cTdetails.startTime.indexOf("GMT") ?
                                        Utility.convertUTCDateToUserTimeZone(cTdetails.startTime) :
                                        Utility.convertUTCDateToUserTimeZone(new Date(cTdetails.startTime).toUTCString())
                                    )
                                    : cTdetails.startTime

                                cTdetails.entryDate =
                                    (cTdetails.entryDate.indexOf("GMT") ?
                                        Utility.convertUTCDateToUserTimeZone(cTdetails.entryDate) :
                                        Utility.convertUTCDateToUserTimeZone(new Date(cTdetails.entryDate).toUTCString())
                                    )

                                commercialtimesheetDetailsTemp.push(cTdetails);
                            });
                            element.commercialtimesheetDetails=commercialtimesheetDetailsTemp;
                            commercialTimesheetTemp.push(element);
                        });
                        if(commercialTimesheetTemp && commercialTimesheetTemp.length>0){
                            response.data[0].commercialTimesheet = commercialTimesheetTemp;
                        }
                        // time zone end



                        if (response.data[0].commercialTimesheet !== null && response.data[0].commercialTimesheet !== undefined && response.data[0].commercialTimesheet.length > 0) {
                            response.data[0].commercialTimesheet.forEach(function (item, index1) {
                                item.index = index1;
                            });
                        }
                        setdisplayProducts(response.data[0].commercialTimesheet);
                        setinitalCasesData(response.data);
                        let totalCounts = response.data[0].commercialTimesheet.length;
                        settotalRecords(totalCounts ? totalCounts : 0);
                        setpageNumber(response.pageNumber ? response.pageNumber : 0);                        
                    } else {
                        setdisplayProducts([]);
                        setinitalCasesData([]);
                        settotalRecords(0);
                    }

                }
            })
            .catch((error) => {
                return Promise.reject(error.response);
            });
    };
    const onOrderChange = (column, event) => {
        setorderByColumn(column);
        setorderDirection(event);
        const field = columns[column].field === "caseReference" ? "CaseReference" :
            (columns[column].field === "hearingDate" ? "HearingDateISO" :
                columns[column].field[0].toUpperCase() + columns[column].field.substring(1));
        setorderBy(field);
    };
    
    const onSelectionChange = (evt, data) => {
        console.log(evt, data);
    }
    
    return (
        <div>
            <div className="row casestructure">
            <CaseData caseDetails={props.caseDetails}/>
            </div>
            {isPreview && <div className="col-10" style={{ marginTop: "1rem" }}>
                <Modal
                    show={isPreview}
                    className="decmodal-content"
                    contentClassName="modal-classContent center"
                    onHide={cancelPreview}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Preview Document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.2.228/build/pdf.worker.min.js">
                    <div style={{ height: "720px" }}>
                      <Viewer
                        fileUrl={
                            PreviewResponse
                        }
                        plugins={[defaultInstance]}
                      />
                    </div>
                  </Worker>
               
                        {/* New button Code End... */}
                        {/* <ReportViewer
                            reportUrl={
                                PreviewResponse
                            }
                            width="100%"
                            height="90%"
                            style={{
                                border: "none",
                                top: "1rem",
                                width: "100%",
                                height: "80vh",
                            }}
                            runat="server"

                        /> */}
                    </Modal.Body>
                </Modal>
            </div>}
            <div style={{ paddingTop: '20px' }}>

                {isdisplayAlert === true &&
                    <AlertDialog isdisplayAlert={isdisplayAlert} updatedisplayAlert={updatedisplayAlert}></AlertDialog>}
                {/* <div className="showingResContainer">
                    <p>Showing {pageNumber > 1 ? ((pageNumber - 1) * recordPerPage) + 1 : pageNumber} - {((pageNumber * recordPerPage) <= totalRecords) ? pageNumber * recordPerPage : totalRecords} of {totalRecords} records</p>
                </div> */}
                {/* <div className="paginDiv1">
                    {totalRecords > 0 ? (
                        <Paginator
                            onMount={onChildMount}
                            rowCount={recordPerPage}
                            className="pagination"
                            totalRecoards={totalRecords}
                            recordsPerPage={recordPerPage}
                            // onPageChange={onChangePage}
                            maxPageLimit={5}

                            paging={true}
                            pageSize={20}
                            showFirstLastPageButtons={false}
                            pageSizeOptions={[5, 10, 20, 50]}
                            paginationType={"stepped"}
                            initialPage={pageNumber}
                            customPagination={{
                                isDisplay: false,
                                totalCount: totalRecords,
                            }}
                        />
                    ) : (
                        ""
                    )}
                </div> */}
            </div>
            <div className="datagrid1 dataTable">
                {notsubmitted && <><div style={{ textAlign: 'start', padding: '5px' }}><span className="action"><i
                    style={{ color: 'darksalmon' }}
                    className="fa fa-warning"
                ></i> Marked timesheet overdue please submit</span></div></>}
                <br></br>
                <div className="disFlexDiv"><p style={{ textAlign: "left" }}>All times in {Utility.userSpecificTimeZone()}</p>
                </div>
                <MatDatagrid
                    rows={displayProducts ? displayProducts : []}
                    columns={columns}
                    grouping={false}
                    title={" "}
                    // paging={true}
                    // showFirstLastPageButtons={false}
                    // paginationType={"stepped"}
                    filtering={false}
                    // pageSizeOptions={[5, 10, 15, 20]}
                    // customPagination={{
                    //     isDisplay: true,
                    //     totalCount: totalRecords,
                    // }}
                    onSelectionChange={onSelectionChange}
                    isCustomHeader={true}
                    selection={false}
                    actionsColumnIndex={-1}
                    onOrderChange={onOrderChange}
                    orderDirection={orderDirection}
                    orderBy={orderByColumn}
                    draggable={false}

                    paging={true}
                    pageSize={recordPerPage}
                    showFirstLastPageButtons={false}
                    pageSizeOptions={[5]}
                    paginationType={"stepped"}
                    customPagination={{
                        isDisplay: false,
                        totalCount: totalRecords,
                    }}

                />
            </div>
            <div className="timesheet-details" style={{ marginBottom: "10px" }} ref={myRef} key={key}>

                {showCommertialTimeSheet &&
                    <CommercialTimesheet
                        hearingCode={hearingCode}
                        caseDetails={props.caseDetails}
                        timesheetDetails={timesheetdetails}
                        cancel={true}
                        index={index}
                        onclick={saveTimesheet}
                        initalCasesData={initalCasesData}
                        notsubmitted={notsubmitted}
                        notsubmittedTimesheet={notsubmittedTimesheet}
                    />
                }
            </div>
        </div >
    );
};
export default CommercialTimesheetGrid