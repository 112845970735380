import * as Utility from "../../utils/utility";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Accordion from 'react-bootstrap/Accordion';
import axios from "axios";
import { GET_INVOICE_STATEMENTS, ALLHEARING_OFFICERS } from "../../api/baseURL";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import './invoices.scss'
import Loading from "../../assets/img/loader.gif";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { decryptItem } from "../../shared/Encrypt";
// Create new plugin instance

const Statements = (props) => {
  const defaultInstance = defaultLayoutPlugin();

  const [selectedOfficerName, setselectedOfficerName] = useState('');
  const [caseSubmission, setcaseSubmission] = useState([]);
  const [fileUrl, setfileUrl] = useState("");
  const [isLoaded, setisLoaded] = useState(false);
  const [isalert, setisalert] = useState(false);
  const [hearingOfficers, setSHearingOfficer] = useState([]);

  const GetAllHearingOfficers = async () => {
    axios
      .get(ALLHEARING_OFFICERS, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setSHearingOfficer(res.data);
        setselectedOfficerName(``);
        setisLoaded(true);

      });
  };
 
  const options = [];
  const hearingOfficersrender = () => {
    const obj = {};
    obj["key"] = 0;
    obj["value"] = 0;
    obj[
      "name"
    ] = `Select Hearing Officer`;
    options.push(obj);
    hearingOfficers
      .filter(
        (u) => u.hearingOfficerFName !== null && u.hearingOfficerLName !== null
      )
      .forEach((item) => {
        const obj1 = {};
        obj1["key"] = item.hearingOfficerCode;
        obj1["value"] = item.hearingOfficerCode;
        obj1[
          "name"
        ] = `${item.hearingOfficerLName.trim()}, ${item.hearingOfficerFName.trim()}`;
        options.push(obj1);
      });

    return (
      <SelectSearch
        options={options}
        search={true}
        name="hearingofficers"
        id="hearingofficers"
        emptyMessage="No record found"
        onChange={selectehearingOfficer}
        getOptions={getOptions}
        value={selectedOfficerName}
        placeholder={selectedOfficerName!==null && selectedOfficerName!==undefined && selectedOfficerName!==''?selectedOfficerName:"Select Hearing Officer"}
      />
    );

  };

  const getOptions=async (searchValue)=>{
    return searchValue?
    JSON.parse(JSON.stringify(options)).filter(element=>element.name.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1)
    :options;
  }

  const selectehearingOfficer = async (e) => {
if(e!=='0' && e!=null){
    const result = hearingOfficers.filter((obj) => {
      return obj.hearingOfficerCode === e;
    });
    setselectedOfficerName(`${result[0].hearingOfficerLName.trim()}, ${result[0].hearingOfficerFName.trim()}`);
    getInvoiceStatments(e);
  }
  else
  {
    setselectedOfficerName(`Select Hearing Officer`);
  }
  };


  useEffect(() => {
    if (!Utility.IsHearingOfficer()) {
      GetAllHearingOfficers();
    }
    else {
     
      getInvoiceStatments(decryptItem ("jCode"));
    }
  }, []);
  const getInvoiceStatments = (user) => {
    setisLoaded(false);

    const url = `${GET_INVOICE_STATEMENTS}/${user}`;

    axios
      .get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (res.status === 200 && res.data !== null && res.data !== undefined) {

          setcaseSubmission(res.data);
          if (res.data !== null && res.data !== undefined && res.data.length > 0
            && res.data[0].fileList !== null && res.data[0].fileList !== undefined && res.data[0].fileList.length > 0) {
            const byteString = atob(res.data[0].fileList[0].fileLink); // Add your base64-encoded byte string here

            // Convert byte string to Uint8Array
            const byteArray = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) {
              byteArray[i] = byteString.charCodeAt(i);
            }

            // Convert Uint8Array to Blob
            const blob = new Blob([byteArray], { type: 'application/pdf' });


            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);
            setfileUrl(url);
          }

        }
        setisLoaded(true);

      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="invoices casesContainer"
        style={{ marginTop: "5rem", padding: "0% 3%" }}>
        <div className="">

          <React.Fragment>
            {!Utility.IsHearingOfficer()
              &&
              <>
                <div className="row">

                  <div className="col-md-7 col-12 invoices-hearing">
                    {hearingOfficers.length > 0 && (

                      <div className="disFlexDiv" style={{textAlign:"left"}}>
                        <label className="hearingOfficer">Hearing Officer</label>
                        {hearingOfficersrender()}
                      </div>
                    )}
                  </div>
                </div>
              </>
            }
          </React.Fragment>
        </div>

        <div className="submissions">
          {isLoaded ? (<>
            <div className="row" style={{ height: "100%" }}>
              <div className={caseSubmission !== null && caseSubmission.length === 0 ? "col-12" : "col-4"}>
                <div className="row">
                  <div className="container-fluid">
                    {caseSubmission !== null && caseSubmission.length === 0 && <div className="mb-1  row" style={{ justifyContent: 'center', }}>No statements available</div>}
                    {caseSubmission !== null && caseSubmission.length > 0 && <>
                      <div>
                        <div className="action mb-3" style={{ height: '30px' }}>

                          <Accordion defaultActiveKey="0">
                            {caseSubmission !== null &&
                              caseSubmission.map((submissions, index1) => {
                                return (

                                  <Accordion.Item eventKey={`${index1}`}>
                                    <Accordion.Header style={{ marginRight: '0px', }}> <div
                                      className="font-weight-bold float-start text-start col-12 mb-3"
                                      style={{ padding: "0" }}
                                    >    <i className="fa fa-folder-o" aria-hidden="true"></i>
                                      <span style={{ marginLeft: '.2rem', }}> {submissions.folderName}</span> </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      {submissions.fileList !== null &&
                                        submissions.fileList.map((file, index) => {

                                          return (<> <div className={`file-submission file-link-div ${index === 0 && index1 === 0 ? "linkbody" : ""}`} id={`fileSubmission_${index}`}>
                                            <a className={`file-link`} href={() => { return false; }} rel="noopener noreferrer" target="_blank" onClick={(event) => {

                                              Array.from(document.querySelectorAll('.file-link-div')).forEach(function (button) {

                                                button.classList.remove("linkbody");
                                              });
                                              const parent = event.target.parentElement.parentElement;
                                              parent.classList.add("linkbody");
                                              if (file.fileName.includes(".pdf")) {
                                                const byteString = atob(file.fileLink); // Add your base64-encoded byte string here

                                                // Convert byte string to Uint8Array
                                                const byteArray = new Uint8Array(byteString.length);
                                                for (let i = 0; i < byteString.length; i++) {
                                                  byteArray[i] = byteString.charCodeAt(i);
                                                }
                                                // Convert Uint8Array to Blob
                                                const blob = new Blob([byteArray], { type: 'application/pdf' });
                                                // Create a URL for the Blob
                                                const url = URL.createObjectURL(blob);
                                                setfileUrl(url);
                                              } else {
                                                setfileUrl('');
                                                setisalert(true);
                                              }
                                            }}>
                                              <i className="fa fa-file file-submission-icon" aria-hidden="true"></i>
                                              <span className="link" style={{ marginLeft: '.2rem', }}>{file.fileName}
                                              </span>
                                            </a>
                                          </div></>)
                                        })}
                                    </Accordion.Body>

                                  </Accordion.Item>
                                )
                              })}
                          </Accordion>
                        </div>
                      </div>
                    </>
                    }
                  </div>
                </div>
              </div>

              <div className="col-8" style={{ height: "100%" }}>
                {fileUrl !== '' &&
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.2.228/build/pdf.worker.min.js" >
                    <div style={{ height: "720px" }}>
                      <Viewer fileUrl={fileUrl} plugins={[defaultInstance]} />
                    </div>
                  </Worker >
                }
                {/* <object data={fileUrl} type="application/pdf">
              <ReportViewer
                reportUrl={fileUrl}
                width="100%"
                height="90%"
                style={{
                  border: "none",
                  top: "0px",
                  width: "100%",
                  height: "80vh",
                }}
                runat="server"
              />
              </object> */}
              </div>


              {isalert && (
                <Modal
                  show={isalert}
                  onHide={() => setisalert(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{"Your document is being downloaded."}</Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setisalert(false)}
                    >
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}
            </div>
          </>) : (
            <div className="LoadingData">
              <iframe src={Loading} width="100%" height="100%" allowtransparency="true" frameBorder="0" marginWidth="0" marginHeight="0" scrolling="no"></iframe>

              <h4>Loading...</h4>
            </div>)
          }
        </div>
      </div>
    </>
  );
};

export default Statements;