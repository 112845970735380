/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { BASE_URL } from '../../api/baseURL'
import * as Utility from "../../utils/utility";
import "./pma.scss";
import "./decision.scss";
import NumberFormat from "react-number-format";
const indexCount = 0;
const DecisionTemplate = (props) => {

  const toUpperCase = (item) => {
    return item !== undefined && item !== null && item !== ""
      ? item.toUpperCase()
      : "";
  };
  const getTotalTime = (timesheets) => {

    const totalspentminutes = calculateTotalTime(timesheets);
    return Utility.toHoursAndMinutes(totalspentminutes);

  };
  const calculateTotalTime = (timesheets) => {

    if (timesheets !== undefined && timesheets !== null) {
      const totalpostMedTimehours = timesheets.postMedTimehours;
      const totalrevDocTimehours = timesheets.revDocTimehours
      const totalpostMedTimeminutes = timesheets.postMedTimeminutes
      const totalrevDocTimeminutes = timesheets.revDocTimeminutes
      const totalhearingDuration = timesheets.hearingDuration
      let totalspentminutes =
        totalpostMedTimeminutes +
        totalrevDocTimeminutes +
        totalhearingDuration +
        totalpostMedTimehours * 60 +
        totalrevDocTimehours * 60;
      const arbitrationTime = totalhearingDuration + totalrevDocTimeminutes + (totalrevDocTimehours * 60)
      totalspentminutes = arbitrationTime < timesheets.hearingScheduledDuration * 60 ? timesheets.hearingScheduledDuration * 60 : arbitrationTime


      return totalspentminutes;
    }
    return 0;
  };

// Grammarly changes...
function extractTextFromHTML(htmlContent) {
  // Create a temporary DOM element to parse the HTML
  let tempElement = document.createElement("div");
  tempElement.innerHTML = htmlContent;

  // Remove all inline style attributes and suggestion spans
  tempElement.querySelectorAll('[style]').forEach(el => el.removeAttribute('style'));
  tempElement.querySelectorAll('.suggestion').forEach(el => el.replaceWith(el.textContent));

  // Replace <br> tags with newline characters
  tempElement.querySelectorAll('br').forEach(br => br.replaceWith('\n'));

  // Replace <p> tags with newline characters for paragraph separation
  tempElement.querySelectorAll('p').forEach(p => {
      // Get the trimmed text content and add a newline if it's not empty
      const text = p.textContent.trim();
      if (text) {
          let newTextNode = document.createTextNode(text + '\n');
          p.replaceWith(newTextNode);
      } else {
          p.replaceWith('\n'); // Treat empty paragraphs as extra line breaks
      }
  });

  // Replace &nbsp; with a regular space
  tempElement.innerHTML = tempElement.innerHTML.replace(/&nbsp;/g, ' ');

  // Use textContent to extract the text, keeping spaces but removing excessive whitespace
  let extractedText = tempElement.textContent;

  // Normalize npnmultiple spaces and newlines: collapse consecutive spaces and preserve two consecutive newlines
  extractedText = extractedText.replace(/[ \t]+/g, ' '); // Collapse consecutive spaces
  //extractedText = extractedText.replace(/\n{3,}/g, '\n\n'); // Replace 3 or more newlines with exactly 2

  // Ensure that there are no unnecessary spaces before or after newlines
  extractedText = extractedText.replace(/ *\n */g, '\n');

  return extractedText.trim(); // Trim final result to avoid unnecessary leading/trailing newlines
}
// Grammarly changes End...
  const strikeThrough = props.caseUnsettled ? 'none' : 'block';
  const pmaDetails = props.pmaDetails;
  const attendies = props.attendies ? props.attendies : "";

  const participants=pmaDetails.participants!=null && pmaDetails.participants!=undefined && pmaDetails.participants.length>0?pmaDetails.participants.filter(x=>{return x.amount!='' && x.amount!=undefined && x.amount!=null}):null;
  console.log("participants",participants);
  return (
    <>
      <div id="decisionTemplate" style={{ display: "block", }}>
        <table width="100%" border="0" cellpadding="0">
          <tr>
            <td>
              <div>
                <img
                  alt=""
                  src={`${BASE_URL}/static/media/namlogo.b809c154.png`}
                  style={{
                    display: "block",
                    width: "300px",
                    height: "100px",
                    marginLeft: "-10px"
                  }}
                />
              </div>
            </td>

          </tr>
        </table>

        <div style={{fontFamily: 'Calibri, sans-serif',}}>
          <div style={{width:'50%',float: 'left',textAlign: "initial"}}>
          <span>
            <b>STATE OF {toUpperCase(props.caseDetails["state"])}</b>
          </span>
        </div>
        <div style={{width:'50%',float: 'right',textAlign: "end"}}><span><b>Arbitration Hearing</b></span></div></div>
          
        <div>
          <div>
            <div>
              <div
                className="text-left font-weight-bold"
                style={{ padding: "5px 0px 7px" }}>
              </div>
            </div>
            <div id="htmldoc" style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}>
              <hr style={{ border: '1px double #c7c7c7' }}></hr>
              <table style={{ width: '100%' }}>
                <tr>
                  <td style={{ width: '50%' }}><div className="text-left font-weight-bold">
                    <b>{props.caseDetails["caseName"]}</b>
                  </div>
                  </td>
                  <td style={{ width: '50%', textAlign: 'right' }}>
                    <div className="text-right font-weight-bold">
                      <b>
                        CLAIM#: {props.caseDetails["partyReference"]}
                        <br />
                        NAM ID: {props.caseDetails["caseReference"]}
                      </b>
                    </div>
                  </td>
                </tr>
              </table>
              <hr></hr>
            </div>
            <>
              <div>
                <div className="border pmasec"
                  style={{ marginTop: "20px", fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}>
                  <div>
                    <span className="text-left">
                      <div className="rightt" style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: strikeThrough }}>
                        {`Arbitration hearing was held on ${Utility.getdate(props.caseDetails["hearingDate"])} at
                        ${Utility.ConvertDate(props.caseDetails["hearingStartTime"])}. Location NAM- VIA ${props.caseDetails["hearingType"]} `}
                        {
                          props.caseDetails["hearingType"] === "IN-PERSON " ?
                            <span className="rightt">
                              {props.caseDetails["hearingLocation"]}
                            </span>
                            : ""
                        } subject to terms of agreement entered into between the parties. Those in attendance included: <span>{attendies}</span>

                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </>
            <>
              {
                <>
                  <div>
                    <div
                      className="border pmasec"
                      style={{ marginTop: "20px", fontFamily: 'Calibri, sans-serif', fontSize: '35px!important' }}
                      id="htmldoc2"
                    >
                      <div className="row" style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', marginBottom: ".5rem", display: strikeThrough }}>
                        <div className="col-md-2">
                          The issue(s) to be decided upon are:
                          &nbsp; &nbsp;
                          {pmaDetails.issueName !== undefined
                            ? pmaDetails.issueName
                            : ""}
                        </div>
                        
                      </div>
                      <div>
                        <span className="text-left">
                          <div className="rightt" style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: strikeThrough }}>
                            {`The arbitrator, ${props.caseDetails["hearingOfficerName"]}, hereby makes the following findings:`}
                          </div>
                          <div className="row" style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}>
                           

                          </div>
                          <div
                            className="row"
                            style={{ marginBottom: "0.5rem" }}
                          >
                            <div className="col-md-9">
                              <span style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', whiteSpace: 'pre-line', display: strikeThrough }}>
                                {pmaDetails && pmaDetails.decision !== null && pmaDetails.decision !== undefined ? extractTextFromHTML(pmaDetails.decision) : ''}
                              </span>
                            </div>
                          </div>
                          {pmaDetails && participants !== undefined &&
                            participants !== null && participants.length>0 &&(
                              <div className="row" style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: strikeThrough }}>
                                <div className="col-md-2 col-12"><b>Award</b></div>
                                <div
                                  className="col-md-9 col-12">
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      flexDirection: "column", display: strikeThrough
                                    }}>
                                    {participants !== undefined &&
                                      participants !== null && participants.length>0? (
                                        participants.map((item, index) => {
                                        return (
                                              <div className="add-participants">
                                               

                                                <span style={{ display: 'inline-block' }}>
                                                  {item.defendantName}
                                                </span>{" "}
                                                <span style={{ display: 'inline-block' }}>
                                                  to pay {item.plaintiffName}
                                                </span>{" "}

                                                {/* <input
                                                      style={{
                                                        marginRight: "1rem",
                                                        border: "none",
                                                        color:'black'
                                                      }}
                                                      required
                                                      type="text"
                                                      disabled={true}
                                                      placeholder="Defendant Name"
                                                      value={item.defendantName}
                                                    /> */}
                                                {/* <span style={{ display: 'inline-block' }}>
                                                      {item.defendantName}
                                                    </span>{" "} */}
                                                {/* <div className="dollerDiv"> */}

                                                ${""}
                                                <NumberFormat
                                                  allowNegative={false}
                                                  disabled={false}
                                                  style={{
                                                    border: "none", fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: strikeThrough, display: 'inline-block'
                                                  }}
                                                  required
                                                  thousandSeparator={true}
                                                  fixedDecimalScale={true}
                                                  name="amtPayable"
                                                  decimalScale={2}
                                                  value={item.amount}
                                                  readOnly
                                                />
                                              </div>
                                        );
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}

                          <br></br>
                          {props.totalSum !== "0.00" &&
                            <div className="row">
                              <b>Total Amount Awarded</b>: <b>$ <NumberFormat
                                    allowNegative={false}
                                    disabled={true}
                                    style={{ width: "13%" }}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={props.totalSum}
                                    displayType={'text'}
                                  /></b>
                            </div>}

                          {participants !== undefined &&
                                participants !== null && participants[0]?.defendantName &&
                                participants[0]?.plaintiffName && participants[0]?.amount
                                &&

                          <div className="row" style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: strikeThrough}}>
                            <div className="col-md-2" ><br></br>By</div>
                            <div className="col-md-3">

                              {/* <input
                                type="text"
                                style={{ width: "100%", border: "none",color:'black' }}
                                placeholder={
                                  props.caseDetails["hearingOfficerName"]
                                }
                                disabled={true}
                              /> */}

                              <span>{props.caseDetails["hearingOfficerName"]}</span>
                              <br />
                              <br />
                            </div>
                          </div>}
                          <div id="timesheetdetailsDecision" className="timesheetdetailsDecision">
                          {pmaDetails.timeSheets !== undefined &&
                            pmaDetails.timeSheets !== null && pmaDetails.timeSheets.length > 0 && (
                              <>
                                
                                  <span style={{ fontWeight: 'bold', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}>Timesheet Details:</span>
                                  <br />
                                  <table className="decision-timesheet table1" style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }} border="1" cellPadding={3} cellSpacing={0}>
                                    <thead>
                                      <tr>
                                        <th scope="col">Hearing date</th>

                                        
                                        <th scope="col">Time (ET)</th>
                                        

                                       

                                        {!props.caseDetails.isCommercialCase &&
                                        <th scope="col">Document review/Decision preparation</th>}
                                        <th scope="col">Total hearing time</th>
                                      </tr>
                                    </thead>
                                    <tbody className="font-weight-bold">
                                      {pmaDetails.timeSheets.map(
                                        (timesheet, index) => {
                                          return (
                                            <>
                                              <tr 
                                               style={{  }}
                                               className={`${timesheet.hearingCode === props.hearingCode ? 'current-hearing' : 'prev-hearing'}`}>
                                                <td data-label="Hearing Date">{Utility.getdate(timesheet.hearingDate)}</td>

                                                

                                       
                                                {!props.caseDetails.isCommercialCase ?
                                                  <td data-label="Time">{timesheet.isCombinedTimeSheet ?

                                                    <span>
                                                      <span>{"Session 1 : " + (
                                                        timesheet.hearingStartTimeUTC && 
                                                        new Date(
                                                          (timesheet.hearingStartTimeUTC.indexOf("GMT") ?
                                                          Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingStartTimeUTC) :
                                                          Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingStartTimeUTC).toUTCString())
                                                        )
                                                        ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                                        
                                                      
                                                      ) + " to " + (timesheet.hearingEndTimeUTC &&
                                                        new Date(
                                                          (timesheet.hearingEndTimeUTC.indexOf("GMT") ?
                                                          Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingEndTimeUTC) :
                                                          Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingEndTimeUTC).toUTCString()))
                                                          
                                                        ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3") 
                                                        
                                                          )}
                                                      </span> <br></br>
                                                      <span>{"Session 2 : " + (timesheet.hearingStartTimeAdditionalUTC && 
                                                      new Date(
                                                        (timesheet.hearingStartTimeAdditionalUTC.indexOf("GMT") ?
                                                        Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingStartTimeAdditionalUTC) :
                                                        Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingStartTimeAdditionalUTC).toUTCString())
                                                      )
                                                      ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                                      
                                                    
                                                    ) + " to " + (timesheet.hearingEndTimeAdditionalUTC && 
                                                      new Date(
                                                        (timesheet.hearingEndTimeAdditionalUTC.indexOf("GMT") ?
                                                        Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingEndTimeAdditionalUTC) :
                                                        Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingEndTimeAdditionalUTC).toUTCString()))
                                                        
                                                      ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                                      
                                                        )}
                                                      </span>
                                                    </span>
                                                    : ( (
                                                      timesheet.hearingStartTimeUTC && 
                                                      new Date(
                                                        (timesheet.hearingStartTimeUTC.indexOf("GMT") ?
                                                          Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingStartTimeUTC) :
                                                          Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingStartTimeUTC).toUTCString())
                                                      )
                                                      ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                                    
                                                    ) + " to " + (timesheet.hearingEndTimeUTC && 

                                                      new Date(
                                                        (timesheet.hearingEndTimeUTC.indexOf("GMT") ?
                                                      Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingEndTimeUTC) :
                                                      Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingEndTimeUTC).toUTCString()))
                                                      ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                                      
                                                    
                                                    ))

                                                  } </td> : <td></td>}


                                               {!props.caseDetails.isCommercialCase && <td data-label="Review of documents & preparation of decision time">{timesheet.revDocTimehours} Hours {timesheet.revDocTimeminutes} Minutes</td>}
                                                <td data-label="Total hearing time">{getTotalTime(timesheet)}</td>

                                              </tr>
                                            </>
                                          );
                                        }

                                      )}
                                    </tbody>
                                  </table>
                                  <br />
                                  <div style={{ display: strikeThrough }}>Total time spent on this case</div>
                                  <span className="font-weight-bold" style={{ color: "#337AB7", fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: strikeThrough }}>

                                    

                                    {Utility.toHoursAndMinutes(
                                      props.caseDetails?.caseDuration
                                    )}

                                  </span>
                                  <br /><br />
                                
                              </>
                            )}
                            </div>
                          <div style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: strikeThrough }}>
                            {`The Arbitrator herein, ${props.caseDetails["hearingOfficerName"]}, affirms, pursuant to CPLR §7507,
                            that I am the Arbitrator of the Arbitration held on ${Utility.getFulldate(
                              props.caseDetails["hearingDate"]
                            )}, at NAM- VIA ${props.caseDetails["hearingType"]} `}
                            {
                              props.caseDetails["hearingType"] === "IN-PERSON " ?
                                <span className="rightt">
                                  {props.caseDetails["hearingLocation"]}
                                </span>
                                : ""
                            } 
                            pursuant to the agreement of the parties.
                             I issued this Arbitration Decision and Award, dated {Utility.getdate(
                              new Date()
                            )}, in the matter of {props.caseDetails["caseName"]}.
                          </div><br></br><br></br>
                          <div className="row" id="signatureDecision" >
                            <p style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: strikeThrough, display: 'inline-block'}}>

                            {/* <div className="col-md-2">Hearing Officer - </div> */}

                            <span style={{fontWeight:'bold' }}><b>Hearing Officer:</b> &nbsp;</span> 
                            <span>{props.caseDetails["hearingOfficerName"]}&nbsp; &nbsp;</span>                            
                            <span>{
                             props.hearingOfficerEmail
                            }</span></p>
                         
                        {/*Docusign AnchorTag */}

                        <div
                          className="col-md-3"
                          style={{
                            color: "white",
                            marginTop: "4rem",
                            border: "0px solid #373737",
                          }}
                        >
                          ${`**signature_${indexCount + 1}**`}
                        </div>
                        <div style={{}}>
                          <div

                            style={{
                              color: "white",
                              marginTop: "1rem",
                              border: "0px solid #373737",
                              width: '50% !important',
                              display: 'inline-block',
                            }}
                          >
                            ${`**fullname_${indexCount + 1}**`}
                          </div>

                          <div
                            style={{
                              color: "white",
                              marginTop: "1rem",
                              border: "0px solid #373737",
                              width: '50% !important',
                              display: 'inline-block',

                              marginLeft: '37rem'
                            }}
                          >
                            ${`**datesigned_${indexCount + 1}**`}
                          </div></div>
                        {/*Docusign AnchorTag End*/}
                        </div>
                        </span>
                      </div>
                    </div>
                    <div
                      className="border pmasec"
                      style={{ textAlign: "left", borderTop: "0px !important" }}
                    ></div>
                  </div>
                </>
              }
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default DecisionTemplate;
