import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n/config';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './store'
import { Helmet } from "react-helmet";

import { HashRouter } from "react-router-dom";
import Maintenance from './maintenance';
import { IsMaintenance } from './api/baseURL';
ReactDOM.render(
  <> <Helmet>
  <meta
    httpEquiv="X-Frame-Options"
    content="SAMEORIGIN"
  />
    <meta http-equiv="Content-Security-Policy" content="frame-ancestors 'self'" />
</Helmet>
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter forceRefresh={true}>
          {IsMaintenance ? (
            <Maintenance />
          ) :
            <App />
          }
      </HashRouter>
    </Provider>
  </React.StrictMode>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
